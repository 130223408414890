/**
 * @generated SignedSource<<816f3eecae241f4dab00312761810670>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TerminateSessionModalRefetchQuery$variables = {
  id: any;
  project_id: string;
};
export type TerminateSessionModalRefetchQuery$data = {
  readonly compute_session_node: {
    readonly id: string;
    readonly status: string | null | undefined;
  } | null | undefined;
};
export type TerminateSessionModalRefetchQuery = {
  response: TerminateSessionModalRefetchQuery$data;
  variables: TerminateSessionModalRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "project_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "project_id",
        "variableName": "project_id"
      }
    ],
    "concreteType": "ComputeSessionNode",
    "kind": "LinkedField",
    "name": "compute_session_node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TerminateSessionModalRefetchQuery",
    "selections": (v1/*: any*/),
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TerminateSessionModalRefetchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42872ac8084736aaf26675f9531456db",
    "id": null,
    "metadata": {},
    "name": "TerminateSessionModalRefetchQuery",
    "operationKind": "query",
    "text": "query TerminateSessionModalRefetchQuery(\n  $id: GlobalIDField!\n  $project_id: UUID!\n) {\n  compute_session_node(id: $id, project_id: $project_id) {\n    id\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c928af0802a6732e5237c3f6c46db99";

export default node;
