/**
 * @generated SignedSource<<04c46e9733ab794920ce72b46b31b7cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VFolderNodesFragment$data = ReadonlyArray<{
  readonly group: string | null | undefined;
  readonly host: string | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly ownership_type: string | null | undefined;
  readonly status: string | null | undefined;
  readonly user: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EditableVFolderNameFragment" | "VFolderPermissionCellFragment">;
  readonly " $fragmentType": "VFolderNodesFragment";
} | null | undefined>;
export type VFolderNodesFragment$key = ReadonlyArray<{
  readonly " $data"?: VFolderNodesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VFolderNodesFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "VFolderNodesFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      "action": "NONE",
      "path": "id"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "host",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownership_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "group",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VFolderPermissionCellFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditableVFolderNameFragment"
    }
  ],
  "type": "VirtualFolderNode",
  "abstractKey": null
};

(node as any).hash = "407033ddd5cd76e06dfc534766637013";

export default node;
