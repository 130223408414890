/**
 * @generated SignedSource<<d57c0906be259f6cb8e6f9de84e5a76e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ResourcePresetSelectQuery$variables = Record<PropertyKey, never>;
export type ResourcePresetSelectQuery$data = {
  readonly resource_presets: ReadonlyArray<{
    readonly name: string | null | undefined;
    readonly resource_slots: string | null | undefined;
    readonly shared_memory: any | null | undefined;
  } | null | undefined> | null | undefined;
};
export type ResourcePresetSelectQuery = {
  response: ResourcePresetSelectQuery$data;
  variables: ResourcePresetSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ResourcePreset",
    "kind": "LinkedField",
    "name": "resource_presets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resource_slots",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shared_memory",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResourcePresetSelectQuery",
    "selections": (v0/*: any*/),
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ResourcePresetSelectQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bd89430b9924d926a1b8e91191746b4f",
    "id": null,
    "metadata": {},
    "name": "ResourcePresetSelectQuery",
    "operationKind": "query",
    "text": "query ResourcePresetSelectQuery {\n  resource_presets {\n    name\n    resource_slots\n    shared_memory\n  }\n}\n"
  }
};
})();

(node as any).hash = "9841642e96d89476c2848bfb093ed9c5";

export default node;
