/**
 * @generated SignedSource<<b19be61ff296c66fb069611a863a19dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EndpointListFragment$data = ReadonlyArray<{
  readonly created_at: string;
  readonly created_user_email: string | null | undefined;
  readonly desired_session_count: number | null | undefined;
  readonly endpoint_id: string | null | undefined;
  readonly name: string | null | undefined;
  readonly open_to_public: boolean | null | undefined;
  readonly replicas: number | null | undefined;
  readonly routings: ReadonlyArray<{
    readonly status: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly status: string | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EndpointOwnerInfoFragment" | "EndpointStatusTagFragment">;
  readonly " $fragmentType": "EndpointListFragment";
}>;
export type EndpointListFragment$key = ReadonlyArray<{
  readonly " $data"?: EndpointListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EndpointListFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EndpointListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endpoint_id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "open_to_public",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replicas",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "desired_session_count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Routing",
      "kind": "LinkedField",
      "name": "routings",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_user_email",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EndpointOwnerInfoFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EndpointStatusTagFragment"
    }
  ],
  "type": "Endpoint",
  "abstractKey": null
};
})();

(node as any).hash = "1d739896ef41c5dd5d9554b273f4be84";

export default node;
