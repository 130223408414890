/**
 * @generated SignedSource<<22448796f65c32134d6b9df636bcf2ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModelCardModalFragment$data = {
  readonly architecture: string | null | undefined;
  readonly author: string | null | undefined;
  readonly category: string | null | undefined;
  readonly created_at: string | null | undefined;
  readonly description: string | null | undefined;
  readonly error_msg: string | null | undefined;
  readonly framework: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly id: string;
  readonly label: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly license: string | null | undefined;
  readonly min_resource: string | null | undefined;
  readonly modified_at: string | null | undefined;
  readonly name: string | null | undefined;
  readonly readme: string | null | undefined;
  readonly row_id: string | null | undefined;
  readonly task: string | null | undefined;
  readonly title: string | null | undefined;
  readonly version: string | null | undefined;
  readonly vfolder: {
    readonly cloneable: boolean | null | undefined;
    readonly host: string | null | undefined;
    readonly id: string | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly vfolder_node: {
    readonly " $fragmentSpreads": FragmentRefs<"ModelCloneModalVFolderFragment">;
  } | null | undefined;
  readonly " $fragmentType": "ModelCardModalFragment";
};
export type ModelCardModalFragment$key = {
  readonly " $data"?: ModelCardModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModelCardModalFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelCardModalFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "row_id",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "author",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modified_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "task",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "architecture",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "framework",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "license",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readme",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "min_resource",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VirtualFolder",
      "kind": "LinkedField",
      "name": "vfolder",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cloneable",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "host",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VirtualFolderNode",
      "kind": "LinkedField",
      "name": "vfolder_node",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModelCloneModalVFolderFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error_msg",
      "storageKey": null
    }
  ],
  "type": "ModelCard",
  "abstractKey": null
};
})();

(node as any).hash = "2a7ce0a135e9f76b9639c02f17809626";

export default node;
