/**
 * @generated SignedSource<<e1765556d5eca830512959bcbf0a45a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VFolderNodeListPageQuery$variables = {
  filter?: string | null | undefined;
  first?: number | null | undefined;
  offset?: number | null | undefined;
  order?: string | null | undefined;
  permission?: any | null | undefined;
  projectId?: string | null | undefined;
};
export type VFolderNodeListPageQuery$data = {
  readonly created: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly deleted: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly vfolder_nodes: {
    readonly count: number | null | undefined;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly status: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"DeleteVFolderModalFragment" | "EditableVFolderNameFragment" | "VFolderNodesFragment">;
      };
    } | null | undefined>;
  } | null | undefined;
};
export type VFolderNodeListPageQuery = {
  response: VFolderNodeListPageQuery$data;
  variables: VFolderNodeListPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offset"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "permission"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v6 = {
  "kind": "Variable",
  "name": "permission",
  "variableName": "permission"
},
v7 = {
  "kind": "Variable",
  "name": "project_id",
  "variableName": "projectId"
},
v8 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v12 = {
  "kind": "Literal",
  "name": "first",
  "value": 0
},
v13 = {
  "kind": "Literal",
  "name": "offset",
  "value": 0
},
v14 = [
  (v11/*: any*/)
],
v15 = {
  "alias": "created",
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": "status in [\"READY\", \"PERFORMING\", \"CLONING\", \"MOUNTED\", \"ERROR\"]"
    },
    (v12/*: any*/),
    (v13/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "concreteType": "VirtualFolderConnection",
  "kind": "LinkedField",
  "name": "vfolder_nodes",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": "deleted",
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": "status in [\"DELETE_PENDING\", \"DELETE_ONGOING\",\"DELETE_COMPLETE\", \"DELETE_ERROR\"]"
    },
    (v12/*: any*/),
    (v13/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "concreteType": "VirtualFolderConnection",
  "kind": "LinkedField",
  "name": "vfolder_nodes",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VFolderNodeListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "VirtualFolderConnection",
        "kind": "LinkedField",
        "name": "vfolder_nodes",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "VirtualFolderEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": "VirtualFolderNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v9/*: any*/),
                        "action": "THROW",
                        "path": "vfolder_nodes.edges.node.id"
                      },
                      (v10/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "VFolderNodesFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DeleteVFolderModalFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditableVFolderNameFragment"
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "THROW",
                  "path": "vfolder_nodes.edges.node"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "vfolder_nodes.edges"
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      (v15/*: any*/),
      (v16/*: any*/)
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "VFolderNodeListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "VirtualFolderConnection",
        "kind": "LinkedField",
        "name": "vfolder_nodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VirtualFolderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VirtualFolderNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "host",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownership_type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "group",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "permissions",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      (v15/*: any*/),
      (v16/*: any*/)
    ]
  },
  "params": {
    "cacheID": "6b4be4167f0e24b6ee3301d50007ee64",
    "id": null,
    "metadata": {},
    "name": "VFolderNodeListPageQuery",
    "operationKind": "query",
    "text": "query VFolderNodeListPageQuery(\n  $projectId: UUID\n  $offset: Int\n  $first: Int\n  $filter: String\n  $order: String\n  $permission: VFolderPermissionValueField\n) {\n  vfolder_nodes(project_id: $projectId, offset: $offset, first: $first, filter: $filter, order: $order, permission: $permission) {\n    edges {\n      node {\n        id\n        status\n        ...VFolderNodesFragment\n        ...DeleteVFolderModalFragment\n        ...EditableVFolderNameFragment\n      }\n    }\n    count\n  }\n  created: vfolder_nodes(project_id: $projectId, first: 0, offset: 0, filter: \"status in [\\\"READY\\\", \\\"PERFORMING\\\", \\\"CLONING\\\", \\\"MOUNTED\\\", \\\"ERROR\\\"]\", permission: $permission) {\n    count\n  }\n  deleted: vfolder_nodes(project_id: $projectId, first: 0, offset: 0, filter: \"status in [\\\"DELETE_PENDING\\\", \\\"DELETE_ONGOING\\\",\\\"DELETE_COMPLETE\\\", \\\"DELETE_ERROR\\\"]\", permission: $permission) {\n    count\n  }\n}\n\nfragment DeleteVFolderModalFragment on VirtualFolderNode {\n  id\n  name\n}\n\nfragment EditableVFolderNameFragment on VirtualFolderNode {\n  id\n  name\n  user\n  group\n  status\n}\n\nfragment VFolderNodesFragment on VirtualFolderNode {\n  id\n  status\n  name\n  host\n  ownership_type\n  user\n  group\n  ...VFolderPermissionCellFragment\n  ...EditableVFolderNameFragment\n}\n\nfragment VFolderPermissionCellFragment on VirtualFolderNode {\n  permissions\n}\n"
  }
};
})();

(node as any).hash = "6afa89db1e3eb1011394488b371e4161";

export default node;
